/* Global */

* {
  font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

/* Chats Page */

.chats-page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.nav-bar {
  width: 100%;
  height: 66px;
  background-color: #490066;
}

.logo-tab {
  position: absolute;
  left: 22px;
  top: 12px;
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.logout-tab {
  position: absolute;
  top: 22px;
  right: 22px;
  color: white;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Navbar */

#navbar-login{
  height: 40px;
  background-color: teal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 15px;
  margin-left: -12px;
  margin-right: -10px;
  margin-top: -10px;
}

/* Login Page */

#login-page {
  margin-top: 38px;
  background: linear-gradient(rgba(255,255,255,0.1),rgba(255,255,255,0.1)),
     url("http://unblast.com/wp-content/uploads/2021/08/Group-Chat-Illustration-1.jpg") center ; 
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  
}

#login-card {
  position: relative;
  top: calc(30vh - 180px);
  left: calc(55vw - 180px);
  padding-top: 36px;
  padding-bottom: 66px;
  width: 320px;
  text-align: center;
  background-color: #6600514f;
  border-radius: 22px;
}

.login-button {
  cursor: pointer;
  color: white;
  padding: 12px;
  border-radius: 8px;
  display: inline-block;
}

.facebook {
  background-color: #3b5998;
}

.google {
  background-color: #4285f4;
  color: white;
}

#loading-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgb(255, 255, 255, 0.22);
}

#loading-icon {
  font-size: 66px;
  position: relative;
  left: calc(50vw - 33px);
  top: calc(50vh - 22px);
}
